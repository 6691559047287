import React, { useState, useRef } from 'react';
import { Row, Col, Modal } from 'react-bootstrap';
import api from '../../services/api';

import ReactGA from 'react-ga4';

import * as Yup from 'yup';
import { Form } from '@unform/web';
import Input from '../../components/Form/input';
import InputMask from '../../components/Form/inputMask';

export function Footer() {
  const formRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  function ModalCenter(props) {    
    return (
      <Modal 
        style={{textAlign:'center'}}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" >
            {/* <h4 className="font-face-bs" style={{color:'#222'}}>Sucesso! Em breve retornaremos.</h4> */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>   
          <h4 className="font-face-bs msgsucess" style={{color:'#222'}}>Sucesso. A mensagem foi enviada!</h4>
        </Modal.Body>
      </Modal>
    );
  }

  async function handleSubmit(data, {reset}) {
    setLoading(true)

    await ReactGA.event({
      action: 'form footer',
      category: 'formularios',
      label: 'fale conosco',
      value: 1,
    })

    try{
      const schema = Yup.object().shape({
      name: Yup.string()
      .required("*"),
      whatsapp: Yup.string()
      .required("*"),
    });

    await schema.validate(data, {
      abortEarly:false,
    })

    const {name, whatsapp} = data;

    await api.post(`/contatos`, {
      name, 
      whatsapp, 
      status: "Pendente"
     });

    await api.post('enviar-email', {
      name, 
      whatsapp,
      assunto:"completou formulário footer stnweb"
    });
     
    setModalShow(true);
    formRef.current.setErrors({});

      setTimeout(() => {
        setLoading(false)
        setModalShow(false)
      }, 4000);

     reset();  

    }catch(err){
      setLoading(false)
      if(err instanceof Yup.ValidationError){
        const errorMessages = {};
        err.inner.forEach(error => {
          errorMessages[error.path] = error.message;
        })
        formRef.current.setErrors(errorMessages);
      }
    }
    
  }

  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener, noreferrer');
  };

  return (
    <div>

      <ModalCenter
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

        <Row className="footer">
          <Col sm={3} className="box-footer">
              <h3 className="font-aquamax laranja">.stn</h3> 
              <p>orgulho e amor pela área. Pioneiros, determinados e apaixonados por tecnologia.</p>
              <ul>
                <li>Design Gráfico Desde 1989</li>
                <li>Sites desde 2002</li>
                <li>Aplicativos desde 2016</li>
              </ul>
          </Col>
          <Col sm={3} className="box-footer">
             <h3 className="font-aquamax laranja">serviços</h3> 
             <p>+ de 400 clientes satisfeitos</p>
              <ul>
                <li>Sistemas Web</li>
                <li>Aplicativos Mobile</li>
                <li>iOS / Android</li>
                <li>Sites Institucionais</li>
                <li>Design Gráfico</li>                
              </ul>
          </Col>
          <Col sm={3} className="box-footer">
              <h3 className="font-aquamax laranja">siga-nos</h3>
              <p>Dá um like!</p>
              <ul>
                <li onClick={() => {openInNewTab('https://www.instagram.com/stnweb/')}} style={{cursor: 'pointer'}}>instagram</li>
                <li onClick={() => {openInNewTab('https://www.facebook.com/stnweb')}} style={{cursor: 'pointer'}}>facebook</li>
                <li>linkedin</li>
              </ul>
          </Col>
          <Col sm={3} className="box-footer">
              <h3 className="font-aquamax laranja">fale conosco</h3>  
              <p>Hoje é um ótimo dia.</p>
              <Form onSubmit={handleSubmit} ref={formRef}>
                <Input label="Nome" name="name" style={{padding:5, marginBottom:10, borderWidth:1}}/>
                <InputMask name="whatsapp" mask="(99)9.9999-9999" label="WhatsApp" placeholder="seu whatsapp" style={{padding:5}}/>
                <button disabled={loading} type="submit" className="btn btn-stnweb" style={{width:'50%', marginTop:10}}>
                  ENVIAR
                </button>
              </Form>
          </Col>
        </Row>

        <div className="bolinha">
          <p>--- • ---</p>
        </div>

        <Row className="copyright">
          <Col sm={12}>      
          &#169; 2022 - Todos os Direitos Reservados | 11 4782-7857 | Design by STNWEB        
          </Col>
        </Row>

    </div>
  );
}