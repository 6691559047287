import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';


import { Container, Row,  Col, } from 'react-bootstrap'
import { Header } from '../../components/Header'

import { FaFileExcel } from "react-icons/fa";

export function NotFound(){
  const navigate = useNavigate();
  const [playvideo, setPlayVideo] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setPlayVideo(true)
    }, 0)
  }, []);

  setTimeout(() => {
    navigate(`/`)
  }, 1000)


  function handleGoTo(routerName){
    navigate(`/${routerName}`)
  }

  const urlvideobg = 'https://res.cloudinary.com/dcqngalxa/video/upload/v1666128221/video_111_vpn3b1.mp4'

  return(

    <div className="overlay"> 

    {playvideo && <video className="videohome"  src={urlvideobg} autoPlay loop muted />}  
    
    <Container fluid className={ playvideo? "bgnot" : "bg" }>
        <Header/>

        <Container className="not-found">
          <Row>
            <Col sm={12}>
              <FaFileExcel style={{fontSize:50}} className="laranja"/>
              <h1>Erro 404!</h1>
              <h2>Página não encontrada.</h2>
              <p onClick={() =>{handleGoTo('')}} style={{cursor: "pointer"}}>Use o menu ou clique aqui para voltar para home.</p>
            </Col>
          </Row>
        </Container>

    </Container>

    </div>
  )
}