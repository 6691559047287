import React, { useState, useEffect } from "react"
import useForm from "../../hooks/useForm"
import { Button, Form, Alert, Row, Col } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import "./CreditCardForm.css"
import Cards from "react-credit-cards"
import "react-credit-cards/es/styles-compiled.css"


import payment from '../../services/payment';

import { getPaymentToken } from '../../utils/getPaymentToken'


export function creditCardType(cc) {
  let amex = new RegExp('^3[47][0-9]{13}$');
  let visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$');
  let cup1 = new RegExp('^62[0-9]{14}[0-9]*$');
  let cup2 = new RegExp('^81[0-9]{14}[0-9]*$');

  let mastercard = new RegExp('^5[1-5][0-9]{14}$');
  let mastercard2 = new RegExp('^2[2-7][0-9]{14}$');

  let disco1 = new RegExp('^6011[0-9]{12}[0-9]*$');
  let disco2 = new RegExp('^62[24568][0-9]{13}[0-9]*$');
  let disco3 = new RegExp('^6[45][0-9]{14}[0-9]*$');

  let diners = new RegExp('^3[0689][0-9]{12}[0-9]*$');
  let jcb = new RegExp('^35[0-9]{14}[0-9]*$');


  if (visa.test(cc))
      return 'visa';

  if (amex.test(cc))
      return 'amex';

  if (mastercard.test(cc) || mastercard2.test(cc))
      return 'mastercard';

  if (disco1.test(cc) || disco2.test(cc) || disco3.test(cc))
      return 'discover';

  if (diners.test(cc))
      return 'diners';

  if (jcb.test(cc))
      return 'jcb';

  if (cup1.test(cc) || cup2.test(cc))
      return 'china_union_pay';

  return undefined;
}

const CreditCardForm = () => {

  const admin = false;


  const { handleChange, handleFocus, handleSubmit, values, errors } = useForm();
  const [tokengerado, setTokengerado] = useState('')
  const [typecard, setTypeCard] = useState('')

  const [charge, setCharge] = useState('')
  const [statusPay, setStatusPay] = useState('')
  const [erro, setErro] = useState('')

  async function getType(){
    const resultado = await creditCardType(values.cardNumber);
    setTypeCard(resultado)
    //console.log(resultado);
  }

    const card = {
      brand: typecard,
      number: values.cardNumber,
      cvv: values.cardSecurityCode, 
      expiration_month: "10", 
      expiration_year: "2026"
    }

  async function getToken(){
    const token = await getPaymentToken("cde3d2f20673c20899e84abff93f3dd6", card);
    setTokengerado(token)
    //console.log(token)
  }


  if(errors.variant === 'success'){
      getType()
      getToken();
  }

  async function paymentNow(){

    try{  
      const response = await payment.post(`/credit-card`, {

        payment: {
              credit_card: {
                installments: 1,
                payment_token:tokengerado,
                billing_address: {
                  street: 'Av. Otacilio tomanik',
                  number: 1136,
                  neighborhood: 'Vila Polopoli',
                  zipcode: '05363101',
                  city: 'São Paulo',
                  state: 'SP'
                },
                customer: {
                  name: 'Luis Henrique Russo',
                  email: 'russo@stnweb.com.br',
                  cpf: '35146319804',
                  birth: '1987-02-02',
                  phone_number: '11953126618'
                }
              }
            },
            items: [{
              name: 'Meu Produto STNWEB FATALITY!',
              value: 500,
              amount: 1
            }],
            shippings: [{
              name: 'Custo de Envio',
              value: 10
            }],
            metadata:{
              notification_url:"https://payment-stnweb.vercel.app/payment-card-return"
            }

      })


      // var body = {
      //   payment: {
      //     credit_card: {
      //       installments: 1,
      //       payment_token:payment_token,
      //       billing_address: {
      //         street: 'Av. Otacilio tomanik',
      //         number: 1136,
      //         neighborhood: 'Vila Polopoli',
      //         zipcode: '05363101',
      //         city: 'São Paulo',
      //         state: 'SP'
      //       },
      //       customer: {
      //         name: 'Luis Henrique Russo',
      //         email: 'russo@stnweb.com.br',
      //         cpf: '35146319804',
      //         birth: '1987-02-02',
      //         phone_number: '11953126618'
      //       }
      //     }
      //   },
      //   items: [{
      //     name: 'Meu Produto STNWEB FATALITY!',
      //     value: 500,
      //     amount: 1
      //   }],
      //   shippings: [{
      //     name: 'Custo de Envio',
      //     value: 10
      //   }],
      //   metadata:{
      //     notification_url:"https://payment-stnweb.vercel.app/payment-card-return"
      //   }
      // }




      setCharge(response.data.charge_id);

      console.log(response.data)

      if(response.data.charge_id){

        const paymentStatus = await payment.post(`/consult-charge`, {
          id:response.data.charge_id
        })
        
        setStatusPay(paymentStatus.data.status);
        console.log(paymentStatus.data.status);
      }
  
  
    }catch(error){
      setErro(error);
      console.log(error);
    }
  }


  useEffect(() => {
    async function consultChargeAfterId(){
      const statusdopagamento = await payment.post('/consult-charge', {
        id:charge
      })
      setStatusPay(statusdopagamento.data.status)
      console.log(statusdopagamento.data.status)
    }

    if(charge !== ""){
      consultChargeAfterId()
    }
  // eslint-disable-next-line 
  },[charge])



  return (
    <div>
      <div className="container">
        <div className="box justify-content-center align-items-center">
          <div className="formDiv">
          <div className="creditCard">
          <Cards
            cvc={values.cardSecurityCode}
            expiry={values.cardExpiration}
            focused={values.focus}
            name={values.cardName}
            number={values.cardNumber}
          />
          </div>

          {statusPay === "waiting" ?
          <div>
            <h3>SEU PEDIDO ESTÁ EM ANALISE</h3>
          </div>
          :
            <Form onSubmit={handleSubmit}>
                <Form.Group>
                  <Form.Control
                    type="number"
                    id="cardNumber"
                    data-testid="cardNumber"
                    name="cardNumber"
                    placeholder="NUMERO DO CARTÃO"
                    value={values.cardNumber}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    isValid={errors.cnumber}
                    style={{marginTop:5}}
                    disabled={errors?.variant === "success"}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Control              
                    type="text"
                    id="cardName"
                    data-testid="cardName"
                    name="cardName"
                    placeholder="NOME IMPRESSO NO CARTÃO"
                    value={values.cardName}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    isValid={errors.cname}
                    style={{marginTop:5}}
                    disabled={errors?.variant === "success"}
                  />
                </Form.Group>          
                <Row>
                  <Col>
                    <Form.Group>
                      {admin &&
                      <Form.Control
                        type="text"
                        name="cardType"
                        id="cardType"
                        data-testid="cardType"
                        placeholder="Visa, Master ..."
                        defaultValue={typecard ? typecard : values.cardType}
                        onFocus={handleFocus}                   
                        style={{marginTop:5}}
                        disabled={errors?.variant === "success"}
                      />}               
                    </Form.Group>
                  </Col>
                  </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Control
                        type="text"
                        id="cardExpiration"
                        data-testid="cardExpiration"
                        name="cardExpiration"
                        placeholder="MM/AA"
                        value={values.cardExpiration}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        isValid={errors.cexp}
                        style={{marginTop:5}}
                        disabled={errors?.variant === "success"}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>                
                      <Form.Control
                        type="number"
                        id="cardSecurityCode"
                        data-testid="cardSecurityCode"
                        name="cardSecurityCode"
                        placeholder="CVV"
                        value={values.cardSecurityCode}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        isValid={errors.ccvv}
                        style={{marginTop:5}}
                        disabled={errors?.variant === "success"}
                      />
                    </Form.Group>
                  </Col>
                </Row>         
                <Row>              
                  <Col>
                    <Form.Group>
                      <Form.Control
                        type="text"
                        id="cardPostalCode"
                        data-testid="cardPostalCode"
                        name="cardPostalCode"
                        placeholder="SEU CEP"
                        value={values.cardPostalCode}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        isValid={errors.cpostal}
                        style={{marginTop:5}}
                        disabled={errors?.variant === "success"}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                {errors.message &&
                  <Alert
                  id="alertMessage"
                  data-testid="alertMessage"
                  variant={errors.variant}
                  show={errors.show}
                  >
                  <strong>{errors.message}</strong>
                  </Alert>
                }

                {tokengerado === "" ?

                <Button
                  size={"block"}
                  data-testid="validateButton"
                  className="btn btn-stnweb"
                  style={{width: "100%", color:'#222', fontWeight:'bold', padding:13, marginTop:10}}
                  id="validateButton"
                  type="submit"
                >
                  VALIDAR DADOS DO CARTÃO
                </Button>              
                :              
                <Button
                  size={"block"}
                  className="btn btn-stnweb"
                  style={{width: "100%", color:'#222', fontWeight:'bold', padding:13, marginTop:10}}
                  onClick={() => {paymentNow()}}
                >
                  PAGAR AGORA
                </Button>}
            </Form>
           }
          </div>          
        </div>
      </div>
    </div>
  );
};

export default CreditCardForm;
