import React, { useState, useLayoutEffect, useRef } from 'react'
import { Container, Row, Col, Modal, Button } from 'react-bootstrap'

import ReactGA from 'react-ga4';
import api from '../../services/api';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import Input from '../../components/Form/input';
import InputMask from '../../components/Form/inputMask';

import { FloatingWhatsApp } from 'react-floating-whatsapp'
import avatarstn from '../../assets/avatar_STN.jpg'

import { Header } from '../../components/Header'
//import { Projects} from '../../components/Projects'
import { Clientes } from '../../components/Clientes'
//import { Gallery} from '../../components/Gallery'
import { Footer} from '../../components/Footer'

import iphone_1 from '../../assets/iphone_home_app_vercelli.png'
import iphone_2 from '../../assets/iphone_home_app_nilson_diniz.png'
import iphone_3 from '../../assets/iphone_home_site_vercelli.png'
import iphone_4 from '../../assets/iphone_home_site_mmv.png'
import send from '../../assets/send-icon.svg'

import dashboard from '../../assets/dashboard_mmv.png'
import designgrfico from '../../assets/design_grafico_3.png'
import appiosandroid from '../../assets/app_ios_android_2.png'
import designdigital from '../../assets/design_digital_sites_institucionais_para_empresas.png'

import { SiMysql } from "react-icons/si"
import { FaArrowRight, FaBootstrap, FaChartLine, FaDraftingCompass, FaNodeJs, FaReact, FaUserTie,} from "react-icons/fa";
import { FaRegFileCode, FaSass, FaUserCog, FaUnlockAlt, FaAddressCard, FaBarcode, FaApple, FaAndroid, FaRegCommentDots} from "react-icons/fa";

export function Home() {

  const [modalShow, setModalShow] = useState(false)
  const [fullscreen] = useState(false)
  const [pause, setPause] =useState(false)
  
  const [designword, setDesignWord] = useState(false)
  const [packageword, setPackageWord] = useState(false)
  const [webappword, setWebAppWord] = useState(false)

  const [randomImage, setRandomImage] = useState('')
  const [playvideo, setPlayVideo] = useState(false)

  const urlvideobg = 'https://res.cloudinary.com/dcqngalxa/video/upload/v1666128221/video_111_vpn3b1.mp4'

  async function mandarEmailQuandoAbreAPaginaHome(){

    function obterOrigemDoTrafego() {
      if (document.referrer) {
          return document.referrer;
      } else {
          return "Tráfego direto ou origem desconhecida";
      }
    }
  
    const origem = obterOrigemDoTrafego();

    await api.post('enviar-email', {
      name: "Cliente não identificado",
      whatsapp:"WhatsApp não identificado",
      assunto:`Abriu página Home -  ${origem}`
    });

    return;
    
  }

  useLayoutEffect(() => {
      setPlayVideo(true)
      mandarEmailQuandoAbreAPaginaHome()
  }, []);


  useLayoutEffect(() => {
    if (!pause) {
        const intervals = [
            { interval: 3000, setDesign: true, setPackage: false, setWebApp: false },
            { interval: 6000, setDesign: false, setPackage: true, setWebApp: false },
            { interval: 9000, setDesign: false, setPackage: false, setWebApp: true }
        ];

        const timers = intervals.map(({ interval, setDesign, setPackage, setWebApp }) => {
            return setInterval(() => {
                setDesignWord(setDesign);
                setPackageWord(setPackage);
                setWebAppWord(setWebApp);
            }, interval);
        });

        return () => timers.forEach(clearInterval);
    }
}, [pause]);


  function randomImg(){
    const images = [
      {name: iphone_1, site: 'https://www.vercellibikes.com.br'},
      {name: iphone_2, site: 'https://apps.apple.com/pt/app/nilson-diniz/id1537402559?platform=iphone'}, 
      {name: iphone_3, site: 'https://www.vercellibikes.com.br'},
      {name: iphone_4, site: 'https://www.mmvdistribuidora.com.br'},
    ];
    const randomImageIndex = Math.floor(Math.random() * Math.floor(images.length));
    return images[randomImageIndex]; 
  }

  useLayoutEffect(() => {
    setRandomImage(randomImg);
  }, []);

  useLayoutEffect(() => {
    if(!pause){
      const interval = setInterval(() => {
        setRandomImage(randomImg);
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [pause]);

  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const corprincipal = '#efd162'
  //efd162
  //3FA4B6

  function Bolinha(){
    return(
      <div className="bolinha">
        <p>--- • ---</p>
      </div>
    )
  }

  function handleOpenModal(){
    setModalShow(true);
    setPause(true);
  }

  function ModalZap(props) {

    const [chat] = useState({
      saudacao: 'Olá, tudo bem? Qual seu Nome?',
      sucesso: ''
    });
  
    const date = new Date().toLocaleTimeString()
    const [loading, setLoading] = useState(false);

    const [clientName, setClientName] = useState('')
    const [clientWhatsApp, setClientWhatsApp] = useState('')
    const [clientMessage, setClientMessage] = useState('')
    const [clientmessageformated, setMessageFormated] = useState('')
    const [clientSucessMessage, setClientSuccessMessage] = useState('')
    const [visible, setVisible] = useState(true)
  
    const [dateName, setDateName] = useState('')
    const [dateZap, setDateZap] = useState('')
    const [dateMessage, setDateMessage] = useState('')
  
    const formName = useRef(null);
    const formZap = useRef(null);
    const formMessage = useRef(null);

    async function handleGetName(data, {reset}) {

      setDateName(new Date().toLocaleTimeString())
  
      try{
        const schema = Yup.object().shape({
        name: Yup.string().required("* obrigatório"),
      });
  
      await schema.validate(data, {
        abortEarly:false,
      })
  
      const {name} = data;
      setClientName(name)
  
      formName.current.setErrors({});
      setLoading(false)
    
      }catch(err){
        setLoading(false)
        if(err instanceof Yup.ValidationError){
          const errorMessages = {};
          err.inner.forEach(error => {
            errorMessages[error.path] = error.message;
          })
          formName.current.setErrors(errorMessages);
        }
      }
    }
  
    async function handleGetWhatsApp(data, {reset}) {
      setDateZap(new Date().toLocaleTimeString())
  
      try{
        const schema = Yup.object().shape({
        whatsapp: Yup.string().test("len", "Número de Celular Inválido", (val) => {
          const val_length_without_dashes = val.replace(/-|_/g, "").length;
          return val_length_without_dashes === 14;
        }).required("*"),
      });
  
      await schema.validate(data, {
        abortEarly:false,
      })
  
      const {whatsapp} = data;
      setClientWhatsApp(whatsapp)
      
      await api.post('enviar-email', {
        name: clientName,
        whatsapp,
        assunto:`completou formulário whatsapp stnweb sem mensagem...`
      });
  
      formZap.current.setErrors({});
      setLoading(false)
    
      }catch(err){
        setLoading(false)
        if(err instanceof Yup.ValidationError){
          const errorMessages = {};
          err.inner.forEach(error => {
            errorMessages[error.path] = error.message;
          })
          formZap.current.setErrors(errorMessages);
        }
      }
    }
  
    async function handleSubmit(data, {reset}) {
      setVisible(false)
      setDateMessage(new Date().toLocaleTimeString())
  
      setLoading(true)
  
      await ReactGA.event({
        action: 'form whatsapp',
        category: 'formularios',
        label: 'modal whatsapp',
        value: 1,
      })
  
      try{
        const schema = Yup.object().shape({
        message: Yup.string().required("* obrigatório"),
      });
  
      await schema.validate(data, {
        abortEarly:false,
      })
  
      const {message} = data;
      setClientMessage(message)

      const clientmessageformated = message.replace(/\s/g, '%20');
      setMessageFormated(clientmessageformated);
  
      await api.post('enviar-email', {
        name: clientName,
        whatsapp: clientWhatsApp,
        assunto:`completou formulário whatsapp stnweb assunto: ${message}`
      });
  
      formMessage.current.setErrors({});
  
      setTimeout(() => {      
        setClientSuccessMessage("Sua Mensaegem foi enviada!")
      }, 4000);
  
      setLoading(false)
  
      setTimeout(() => {      
        setModalShow(false)
        setVisible(true)
      }, 20000);
  
      setTimeout(() => {
        handleClearStates()      
        setClientSuccessMessage('')
      }, 20000);
  
      }catch(err){
        setLoading(false)
        if(err instanceof Yup.ValidationError){
          const errorMessages = {};
          err.inner.forEach(error => {
            errorMessages[error.path] = error.message;
          })
          formMessage.current.setErrors(errorMessages);
        }
      }      
    }
  
    function handleClearStates(){
      setClientName('')
      setClientWhatsApp('')
      setClientMessage('')
    }


    return (
      <Modal
        {...props} size=""
        fullscreen={fullscreen}
        className="meumodal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton style={{ backgroundColor: '#008069' }}>
          <Modal.Title id="contained-modal-title-vcenter" style={{color:"#fff", fontWeight:'bold',fontSize:15}}>
            Olá! Sou o Assistente Virtual da StnWeb<br/>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
          {clientSucessMessage === "" && 
          <p style={{color:'#444', fontSize:14}}>A equipe normalmente responde em alguns segundos.</p>
          }
          {clientSucessMessage === "" &&
          <p className="modal-conversation" style={{maxWidth:"60%"}}>
            {chat.saudacao}
            <span style={{display:"flex", justifyContent:'flex-end', fontSize:10}}>{date}</span>
          </p>
          } 
          {clientName !== "" && clientSucessMessage === "" &&
            <p className="modal-conversation-long" style={{maxWidth:"90%"}}>
              {clientName}, digite seu whatsApp para continuar
              <span style={{display:"flex", justifyContent:'flex-end', fontSize:10}}>{dateName}</span>
            </p>
          }
          {clientWhatsApp !== "" && clientSucessMessage === "" &&
            <p className="modal-conversation-long" style={{maxWidth:"80%"}}>
              Seu contato foi enviado. {clientWhatsApp} <br/> Agora, me conte sua necessidade...
              <span style={{display:"flex", justifyContent:'flex-end', fontSize:10}}>{dateZap}</span>
            </p>
          }
          {clientMessage !== "" && clientSucessMessage === "" &&
            <p className="modal-conversation-long">
              {clientMessage}
              <span style={{display:"flex", justifyContent:'flex-end', fontSize:10}}>{dateMessage}</span>
            </p>
          }
          {clientSucessMessage !== "" &&
            <>
              <p className="modal-conversation-long">
                {clientSucessMessage}
                <span style={{display:"flex", justifyContent:'flex-end', fontSize:10}}>{dateMessage}</span>
              </p>
              <p className="modal-conversation-long">
              Retornaremos em breve seu contato ou <br/>Clique em 
              <strong style={{cursor:'pointer', color:"#32f570", marginLeft:5}} onClick={() => openInNewTab(`https://wa.me/5511953126618?text=Tenho%20interesse%20em%20serviços%20da%20Stn`)}>
                FALAR AGORA</strong><br/> Obrigado.
                <span style={{display:"flex", justifyContent:'flex-end', fontSize:10}}>{dateMessage}</span>
              </p>
            </>
          }
        </Modal.Body>

        <Modal.Footer style={{ backgroundColor: '#008069'}}>
            {clientName === "" && 
              <Form onSubmit={handleGetName} ref={formName} className="input-send">
                <Input placeholder="Digite seu Nome" name="name" style={{padding:5, backgroundColor: '#fff', borderColor:'#fff', borderWidth:1}} />              
                <Button disabled={loading} type="submit" className='button-send'>
                  <img src={send} width="20" height="20" alt="enviar"/>
                </Button>
              </Form>
            }
            {clientName !== "" && clientWhatsApp === "" &&
              <Form onSubmit={handleGetWhatsApp} ref={formZap} className="input-send">
                <InputMask placeholder="Seu WhatsApp com DDD" mask="(99)9.9999-9999" name="whatsapp" label="" style={{padding:5}} />              
                <Button disabled={loading} type="submit" className='button-send'>
                  <img src={send} width="20" height="20" alt="enviar"/>
                </Button>
              </Form>
            }
            {clientName !== "" && clientWhatsApp !== "" &&
              <Form onSubmit={handleSubmit} ref={formMessage} className="input-send">
                {clientSucessMessage === "" && visible ? 
                  <>
                    <Input placeholder="Digite sua Mensagem" name="message" style={{padding:5, width:'70%'}}/>             
                    <Button disabled={loading} type="submit" className='button-send' style={{width:'30%'}}>
                      ENVIAR <img src={send} width="20" height="20" alt="enviar"/>
                    </Button>
                  </>
                :
                  <>
                    <Button onClick={props.onHide} 
                    className='button-loading'>
                    AGUARDAR CONTATO
                    </Button>

                    <Button 
                    onClick={() => openInNewTab(`https://wa.me/5511953126618?text=${clientmessageformated}`)} 
                    className='button-talk'>
                    FALAR AGORA  <img src={send} width="20" height="20" alt="enviar"/>
                    </Button>
                  </>
                }
              </Form>
            }
        </Modal.Footer>
      </Modal>
    );
  }


  function WhatsAppButton(){
    return(
      <div className='wahtsapp-button' onClick={handleOpenModal}>
        {/* <FaWhatsapp/>  */}
        <FaRegCommentDots/>
      </div>
    )
  }


  async function whatsAppOpenInWeb(){
    await api.post('enviar-email', {
      name: "Cliente não identificado",
      whatsapp:"WhatsApp não identificado",
      assunto:`Abriu WhatsApp`
    });
  }


  function formatarFrase(frase) {
    var fraseFormatada = frase.replace(/ /g, "%20");
    return fraseFormatada;
}

  return (
    <div className="overlay">

      <ModalZap
        show={modalShow}
        onHide={() => {setModalShow(false); setPause(false);}}
      />

      <FloatingWhatsApp 
        phoneNumber="+5511953126618"
        accountName="StnWeb"
        statusMessage="Design, DashBoards, Web e App!"
        chatMessage = "Olá, como posso ajudar?"
        placeholder = "Digite aqui sua mensagem"
        avatar = {avatarstn}
        allowEsc
        allowClickAway = {false}
        chatboxHeight = {320}
        notification
        notificationSound
        darkMode
        buttonStyle={{backgroundColor: '#008069'}}
        onClick={() => whatsAppOpenInWeb()}
      />

    <WhatsAppButton/>

    {playvideo && <video className="videohome"  src={urlvideobg} autoPlay loop muted />}  
    
    <Container fluid className={ playvideo? "bgnot" : "bg" }>
        <Header/>

          <Container>
              <Row>
                <Col sm={7} className="box-title-stn">          
                  <h1 className="font-aquamax titlestnweb">
                    <span style={designword === true ?  {color:corprincipal} : {color:'#f3f3f3'}}>design,</span><br/>
                    <span style={packageword === true ? {color:corprincipal} : {color:'#f3f3f3'}}>dashboards,</span><br/>
                    <span style={webappword === true ?  {color:corprincipal} : {color:'#f3f3f3'}}>web e app.</span><br/>
                    </h1>
                  <h2 className="font-aquamax" style={{color:corprincipal, paddingTop:30}}>
                    CONHEÇA O NOVO
                  </h2>
                  <p><strong>Somos uma agência de Marketing Digital em São Paulo</strong> especializada em web, aplicativos mobile, sites, e-commerce, sistemas e dashboards customizados. 
                    Da criação de designs cativantes à implementação de soluções personalizadas, capacitamos nossos clientes para se destacarem no mundo digital.
                  </p>
                </Col>
                <Col sm={5} className="iphone_home">
                  <img onClick={() => openInNewTab(`${randomImage.site}`)} src={randomImage.name} alt="app_stnweb"/>
                </Col>
              </Row>

              <Row className="trheebox">
                <Col sm={4} className="one-box">
                  <h3 className="mb"><FaRegFileCode style={{color:corprincipal}}/></h3>
                  <h3 className="font-aquamax laranja"><strong>Web e App </strong>iOS/Android</h3>
                  <p>Stack escolhida pelas maiores empresas do mundo contendo: 
                    Autenticação, métodos de pagamento e muitas outras tecnologias são implementadas <br/>em nossos projetos web e mobile.</p>
                  <p className="tecnologias azulzinho">
                    <span><FaNodeJs/></span>
                    <span><FaReact/> </span>
                    <span><SiMysql/></span>
                    <span><FaBootstrap/></span>
                    <span><FaSass/></span>
                  </p>
                  <p>Time de Desenvolvedores Back-End, <br/>Web e Mobile. Só vem! Simples assim.</p>
                  <div className="actions_btn">
                  <button onClick={() => openInNewTab(`https://wa.me/5511953126618?text=${formatarFrase('Tenho interesse em Aplicativos Mobile')}`)} style={{backgroundColor:corprincipal}} type="" className="btn btn-stnweb  btn-info"> <strong>Dashboard</strong> & App! <FaArrowRight/></button>
                  </div>
                </Col>
                <Col sm={4} className="one-box">
                  <h3 className="mb"><FaChartLine style={{color:corprincipal}}/></h3>
                  <h3 className="font-aquamax laranja"><strong>DashBoard</strong> Customizado</h3>
                  <p>Tenha Controle total da <br/>sua empresa ou negócio. Tecnologias em <br/>Tempo Real para auxiliar seu time de vendas.<br/> Isso é Perfeito.</p>
                  <p className="tecnologias-dash azulzinho">
                    <span><FaUserCog/></span>
                    <span> <FaUnlockAlt style={{fontSize:27}}/> </span>
                    <span><FaUserTie style={{fontSize:26}}/></span>
                    <span> <FaBarcode style={{fontSize:30}}/></span>
                    <span> <FaAddressCard style={{fontSize:30}}/></span>
                  </p>
                  <p>Administre e tenha total controle de sua <br/> empresa utlizando nossas incríveis <br/>ferramentas customizadas.</p>   
                  <div className="actions_btn">
                    <button style={{backgroundColor:corprincipal}} type="" onClick={() => openInNewTab(`https://wa.me/5511953126618?text=${formatarFrase('Tenho interesse em Dashboard')}`)} className="btn btn-stnweb  btn-info">O <strong>Poder</strong> em <strong>1</strong> clique. <FaArrowRight/></button>
                  </div>
                </Col>
                <Col sm={4} className="one-box">
                  <h3 className="mb"><FaDraftingCompass style={{color:corprincipal}}/></h3>
                  <h3 className="font-aquamax laranja">Design <strong>Gráfico</strong></h3>
                  <p>Somos pioneiros em SilkScreen no Brasil. <br/>A técnica e a qualidade está a cima de<br/> outros meios gráficos como o<br/> offset, impressoras digitais, etc.</p>
                  
                  <p>Desenvolvemos Brindes corporativos <br/>personalizados em SilkScreen. <br/>Traga sua idéia, passamos literalmente<br/> pro papel. Abaixo tem info whatsapp:</p>
                  <div className="actions_btn">
                    <button style={{backgroundColor:corprincipal}} onClick={() => openInNewTab(`https://wa.me/5511953126618?text=${formatarFrase('Tenho interesse em Design Gráfico')}`)} type="" className="btn btn-stnweb  btn-info">Design <strong>Gráfico <FaArrowRight/></strong></button>
                  </div>
                </Col>
              </Row>
          </Container>

          <Bolinha/>

          <Container>
            <Row className="box-dashboard">
              <Col sm={5} className="img-dashboard" >
                <img src={dashboard} alt="dashboard stnweb mmv"/>
              </Col>
              <Col sm={6}>
                <h2><strong style={{color:corprincipal}}>DashBoards</strong> Customizados</h2>
                <p><strong>Dashboards integrados com sua aplicação ou App Mobile</strong></p>
                <Row>
                  <Col sm={6}>
                    <ul>
                      <li>Autenticação</li>
                      <li>Administração de Vendedores</li>
                      <li>Administração de Clientes</li>
                      <li>Métodos de Pagamento</li>
                      <li>Carrinho</li>
                    </ul>
                  </Col>
                  <Col sm={6}>
                    <ul>
                      <li>Sys Orçamentos/ Pedidos</li>
                      <li>Vitrine de Produtos</li>
                      <li>UpLoad e Storage Files</li>
                      <li>Automação de Contratos</li>
                      <li>Pedidos em Tempo Real</li>
                    </ul>
                  </Col>
                </Row>
                <p><strong>Precisa de uma solução customizada para sua Empresa?</strong></p>
                <button style={{backgroundColor:corprincipal}} onClick={() => openInNewTab(`https://wa.me/5511953126618?text=${formatarFrase('Tenho interesse em DashBoards Customizados')}`)} type="" className="btn btn-stnweb btn-info"><FaArrowRight/> <strong>Dashboard</strong> & App!</button>
              </Col>
            </Row>
          </Container>

          <Bolinha/>

          <Container>
            <Row className="box-appiosandroid">
              <Col sm={4} className="img-appiosandroid-mobile">
                <img src={appiosandroid} alt="aplicativos ios e android stnweb"/>
              </Col>
              <Col sm={5}>
                <h2><strong style={{color:corprincipal}}>Aplicativos </strong> iOS/Android</h2>
                <p><strong>Já desenvolvemos muitos aplicativos com diversas finalidades</strong></p>
                <Row>
                  <Col sm={6}>
                    <ul>
                      <li>Geolocalição</li>
                      <li>Agendamentos</li>
                      <li>Logística</li>
                      <li>Meteorologia</li>
                      <li>AgroNegócio</li>
                      <li>Internet das Coisas (IOT)</li>
                    </ul>
                  </Col>
                  <Col sm={6}>
                    <ul>
                      <li>Gameficação</li>
                      <li>E-commerce</li>
                      <li>Orcamentos</li>
                      <li>Mecatronica</li>
                      <li>Cálculos</li>
                      <li>Contadores</li>
                    </ul>
                  </Col>
                </Row>
                <h5>App's cross-plataform  <FaApple  className="azulzinho"/> | <FaAndroid style={{marginTop:4}}  className="azulzinho"/></h5>
                <p></p>
                <p><strong>Pioneiros, determinados e apaixonados por tecnologia.</strong></p>
                  <button style={{backgroundColor:corprincipal}} onClick={() => openInNewTab(`https://wa.me/5511953126618?text=${formatarFrase('Tenho interesse em Aplicativos iOS/Android')}`)} type="" className="btn btn-stnweb btn-info">
                    <strong> Envie o Briefing</strong> sobre o projeto! <FaArrowRight/>
                  </button>              
              </Col>
              <Col sm={4} className="img-appiosandroid">
                <img src={appiosandroid} alt="aplicativos ios e android stnweb"/>
              </Col>
            </Row>
          </Container>

          <Bolinha/>

          <Container>
            <Row className="box-designgrafico">
              <Col sm={6} className="img-designdigital" >
                <img src={designdigital} alt="design grafico stn studio de arte"/>
              </Col>

              <Col sm={6}>
                <h2><strong style={{color:corprincipal}}>Sites </strong>Institucionais</h2>
                <p><strong>Desenvolvimento de Sites Institucionais com as tecnologias <br/> mais modernas. Nossa aquipe Web atua <strong style={{color:corprincipal}}>desde 2002.</strong></strong></p>
                <Row>
                  <Col sm={6}>
                    <ul>
                      <li>Sites em WP, Joomla e Magento</li>
                      <li>Integração de Sistemas Web</li>
                      <li>Utilização de API de Terceiros</li>
                      <li>Ferramentas para Clientes</li>
                      <li>Formulários interativos</li>
                      <li>Automação de Atendimento</li>
                    </ul>
                  </Col>
                  <Col sm={6}>
                    <ul>
                      <li>E-commerce Magento</li>
                      <li>Métodos de Pagamento</li>
                      <li>Métodos de Envios</li>
                      <li>Sistemas Administrativos</li>
                      <li>Pedidos e Orçamentos</li>
                      <li>Automação de Tarefas</li>
                    </ul>
                  </Col>
                </Row>
                <p><strong>Exigentes e focados no resultado.</strong></p>
                <button style={{backgroundColor:corprincipal}} onClick={() => openInNewTab(`https://wa.me/5511953126618?text=${formatarFrase('Tenho interesse em Sites Institucionais')}`)} type="" className="btn btn-stnweb btn-info">
                  <strong> Envie o Briefing</strong> sobre o projeto! <FaArrowRight/>
                </button>
              </Col>
            </Row>
          </Container>

          <Bolinha/>

          <Container>
            <Row className="box-designgrafico">

              <Col sm={5} className="img-designgrafico-mobile">
                <img src={designgrfico} alt="design grafico stn studio de arte"/>
              </Col>

              <Col sm={1}></Col>
              <Col sm={5}>
                <h2><strong style={{color:corprincipal}}>Design </strong>Gráfico</h2>
                <p><strong>Embalagens personalizadas, facas especiais, impressões em alta qualidade são itens agregados
                  à stn studio de arte <strong style={{color:corprincipal}}>desde 1989.</strong></strong></p>
                <Row>
                  <Col sm={6}>
                    <ul>
                      <li>Painéis para Equipamentos</li>
                      <li>Cases Personalizadas</li>
                      <li>Uniformes</li>
                      <li>Papelaria Premium</li>
                      <li>Brindes Corporativos</li>
                      <li>Matrizes especiais</li>
                    </ul>
                  </Col>
                  <Col sm={6}>
                    <ul>
                      <li>Embalagens</li>
                      <li>Impressão em SilkScreen</li>
                      <li>Anúncios de Revista</li>
                      <li>Etiquetas em Policarbonato</li>
                      <li>Sacolas Personalizadas</li>
                      <li>Cartões, Pastas e Brindes</li>
                    </ul>
                  </Col>
                </Row>
                <p><strong>Exigentes e focados no resultado.</strong></p>
                <button style={{backgroundColor:corprincipal}} onClick={() => openInNewTab(`https://wa.me/5511953126618?text=${formatarFrase('Tenho interesse em Design Gráfico')}`)} type="" className="btn btn-stnweb btn-info">
                  <strong> Envie o Briefing</strong> sobre o projeto! <FaArrowRight/>
                </button>
              </Col>
              <Col sm={1}></Col>
              <Col sm={5} className="img-designgrafico">
                <img src={designgrfico} alt="design grafico stn studio de arte"/>
              </Col>
            </Row>
          </Container>
          
          <Container>
            <Row>
              <Col sm={12}>
                {/* <Projects/> */}
              </Col>
            </Row>
          </Container> 

          <Bolinha/>

          <Container>
            <Row className="clientes font-aquamax">
              <Clientes/>
            </Row>
          </Container>
          
          <Container>
            <Row>
              <Col sm={12}>
                {/* <Gallery/> */}
              </Col>
            </Row>
          </Container> 
         
          <Container>
            <Footer/>
          </Container>

     </Container> 
    </div>
  )
}


/*  
  //import { useNavigate } from 'react-router-dom';
  //const navigate = useNavigate();

  https://www.nicesnippets.com/blog/how-to-create-random-image-in-react-native

   onClick={() =>{setPlayVideo(prevstate => !prevstate)}}

   https://www.pexels.com/video/time-lapse-of-sky-at-night-10079386/

*/