import React from 'react';
import { Route, Routes } from 'react-router-dom'

import ReactGA from 'react-ga4';

import { Home } from './screens/Home'
import { Login } from './screens/Login'
import { Orcamentos } from './screens/Orcamentos'
import { Payments } from './screens/Payments'

import { NotFound } from './screens/NotFound'

ReactGA.initialize("G-7MTSQKLV5C")

export function AppRoutes() {
  return (
    <div className='App'>
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/login" element={<Login />}/>
        <Route path="/orcamentos" element={<Orcamentos />}/>
        <Route path="/payments" element={<Payments />}/>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}