import React, { useContext, useState, useEffect } from "react";

const CartContext = React.createContext()

export function useCart() {
  return useContext(CartContext);
}

export function CartProvider({ children }) {

  const products = [
    {
      id:1,
      name:"Hospedagem Anual",
      description:"Descrição",
      stock:999,
      price:399,
      descount:0,
      category:"Serviços",
      img:"https://res.cloudinary.com/dcqngalxa/image/upload/v1673527942/filone/products/FiloneInteiro_1225_1200x800_1x_pwbrlj.jpg"
    }
  ]

  const [productsCart, setProductsCart] = useState(
    JSON.parse(localStorage.getItem('products_cart')) || []
  );

  const [totalpriceproduct, setTotalPriceProduct] = useState(0)
  const [subtotal, setSubTotal] = useState(0)
  const [visible, setVisible] = useState(false)

  function addProducToCart(id, name, ...rest) {
    const copyProductsCart = [...productsCart];    
    const item = copyProductsCart.find((product) => product.id === id);
      if (!item) {
        copyProductsCart.push({id, name, qtd: 1, ...rest});
      } else {
        item.qtd = item.qtd + 1;
      }
      setProductsCart(copyProductsCart);
      setVisible(true);
      localStorage.setItem('products_cart', JSON.stringify(copyProductsCart));
  }


  function removeProductToCart(id) {
    const copyProductsCart = [...productsCart];
    const item = copyProductsCart.find((product) => product.id === id);
      if(item && item.qtd > 1){
        item.qtd = item.qtd - 1;
        setProductsCart(copyProductsCart);
        localStorage.setItem('products_cart', JSON.stringify(copyProductsCart));
      } else {
        const arrayFiltered = copyProductsCart.filter((product) => product.id !== id);
        setProductsCart(arrayFiltered);
        localStorage.setItem('products_cart', JSON.stringify(arrayFiltered));
      }
  }

  useEffect(() => {
    let total = 0;
    productsCart.map(item => (
      total += parseFloat(item.price * item.qtd)
    ));
    setTotalPriceProduct(total);
  },[productsCart]);


  useEffect(() => {  
    const subtotal = productsCart.reduce((prevVal, elem) => prevVal + elem.price, 0);
    setSubTotal(subtotal)
  }, [productsCart])

  function clearCart() {    
    setProductsCart([]);
    localStorage.clear();
  }
  
  const value = { 
    products,
    productsCart,
    addProducToCart,
    removeProductToCart,
    totalpriceproduct,
    subtotal,
    clearCart,
    visible,
    setVisible,
    }
    
  return (
    <CartContext.Provider value={value}>
      {children}
    </CartContext.Provider>
  )
}