import React, { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import villaaroma from '../../assets/clientes/villa_aroma.png'
import cosambiental from '../../assets/clientes/cosambiental.png'
import waves from '../../assets/clientes/waves.png'
import cerimonialdovalle from '../../assets/clientes/cerimonialdovalle.png'
import europavision from '../../assets/clientes/europavision.png'
import ligiapannunzio from '../../assets/clientes/ligiapannunzio.png'
import nppcoifas from '../../assets/clientes/nppcoifas.png'
import designcoifas from '../../assets/clientes/designcoifas.png'
import ourogrill from '../../assets/clientes/ourogrill.png'
import mmvdistribuidora from '../../assets/clientes/mmv_distribuidora.png'
import vercelli from '../../assets/clientes/vercelli_bikes.png'
import roberto_motta from '../../assets/clientes/roberto_motta.png'
import ras from '../../assets/clientes/ras.png'
import nilson_diniz from '../../assets/clientes/nilson_diniz.png'
import viannagrill from '../../assets/clientes/vianna_grill.png'
import inoxgrill from '../../assets/clientes/inoxgrill.png'
import psr_cacambas from '../../assets/clientes/psr_cacambas.png'
import friocold from '../../assets/clientes/friocold.png'
import stelluto_paralegal from '../../assets/clientes/stelluto_paralegal.png'
import resinaeart from '../../assets/clientes/resinaeart.png'
import fernando_bianchi_fotografia from '../../assets/clientes/fernando_bianchi_fotografia.png'
import nucleo_holistico_de_luz from '../../assets/clientes/nucleo_holistico_de_luz.png'
import oxitab from '../../assets/clientes/oxitab.png'
import dasher from '../../assets/clientes/dasher.png'

import {FaPlus, FaChevronUp} from "react-icons/fa"

const openInNewTab = url => {
  window.open(url, '_blank', 'noopener,noreferrer')
};

export function Clientes() {

  const [primeiros] = useState(true)
  const [todos, setTodos] = useState(true)
  const [setinha, setSetinha] = useState(true)


  return(
    <Container>
      <Row>
        <Col sm={12} style={{textAlign: 'center'}}>
          {/* <h3 style={{color:'#fff'}}>Nossos <strong>Clientes</strong></h3>
          <h4> </h4> */}
        </Col>
      </Row>
      <Row >
        <Col sm={12}>

        {primeiros && <>
            <img onClick={() => openInNewTab('https://www.villaaroma.com.br')} src={villaaroma} alt="logo villa aroma"/>
            <img onClick={() => openInNewTab('https://www.vercellibikes.com.br')} src={vercelli} alt="logo vercelli"/>
            <img onClick={() => openInNewTab('https://www.mmvdistribuidora.com.br')} src={mmvdistribuidora} alt="logo mmvdistribuidora"/>
            <img src={dasher} alt="logo dasher"/>
            <img src={waves} alt="logo waves"/>
                        
            <img onClick={() => openInNewTab('https://www.cosambiental.com.br')} src={cosambiental} alt="logo cosambiental"/>
            <img onClick={() => openInNewTab('https://www.cerimonialdovalle.com.br')} src={cerimonialdovalle} alt="logo cerimonialdovalle"/>         
            <img onClick={() => openInNewTab('https://www.ligiapannunzio.com.br')} src={ligiapannunzio} alt="logo ligiapannunzio"/>          
            <img onClick={() => openInNewTab('https://www.designcoifas.com.br')} src={designcoifas} alt="logo designcoifas"/>
            <img className="mobile-nao-ta" onClick={() => openInNewTab('https://www.outrogrill.com.br')} src={ourogrill} alt="logo ourogrill"/>
          </>
        }

        {todos && <>
            <img onClick={() => openInNewTab('https://www.europavision.com.br')} src={europavision} alt="logo europavision"/>
            <img onClick={() => openInNewTab('https://www.colegiorobertomotta.com.br')} src={roberto_motta} alt="logo roberto motta"/>
            <img onClick={() => openInNewTab('https://www.rasbrindes.com.br')} src={ras} alt="logo ras"/>
            <img onClick={() => openInNewTab('https://www.nilsondiniz.com.br')} src={nilson_diniz} alt="logo nilson_diniz"/>
            <img src={psr_cacambas} alt="logo psr_cacambas"/>

            <img onClick={() => openInNewTab('https://www.friocold.com.br')} src={friocold} alt="logo friocold"/>
            <img onClick={() => openInNewTab('https://www.vianagrill.com.br')} src={viannagrill} alt="logo viannagrill"/>
            <img onClick={() => openInNewTab('https://www.inoxgrill.com.br')} src={inoxgrill} alt="logo inoxgrill"/>
            <img src={stelluto_paralegal} alt="logo stelluto paralegal"/>
            <img onClick={() => openInNewTab('https://www.resinaeart.com.br')} src={resinaeart} alt="logo resinaeart"/>

            <img src={nppcoifas} alt="logo nppcoifas"/>
            <img onClick={() => openInNewTab('https://www.fernandobianchifotografia.com.br')} src={fernando_bianchi_fotografia} alt="logo fernando bianchi fotografia"/>          
            <img  src={nucleo_holistico_de_luz} alt="logo núcleo holistico de luz"/>
            <img onClick={() => openInNewTab('https://www.oxitab.com.br')} src={oxitab} alt="logo oxitab"/>
            <img onClick={() => openInNewTab('https://www.mmvdistribuidora.com.br')} src={mmvdistribuidora} alt="logo mmvdistribuidora"/>
          </>
        }

        </Col>        
      </Row>

      <Row>
        <Col sm={12} className="arrow-action azulzinho">
          {!setinha ? <FaPlus style={{fontSize:20}} onClick={() => {setSetinha(prevstate => !prevstate); setTodos(prevstate => !prevstate);}}/> :
          <FaChevronUp style={{fontSize:30}} onClick={() => {setSetinha(prevstate => !prevstate); setTodos(prevstate => !prevstate);}}/>}
        </Col>
      </Row>
    </Container>      
  )
}