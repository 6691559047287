import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from "../../contexts/AuthContext";

//import home from '../../assets/home.svg';
//import user from '../../assets/user.png';

import { FaPowerOff } from "react-icons/fa";


export function Menu() {
  const navigate = useNavigate();

  const { logout, currentUser } = useAuth();
  const [ error, setError ] = useState('');

  async function handleLogoutSubmit() {
    try {
      setError('')
      await logout();
    } catch(error) {
      setError(`Falha no Logout`);
    }
  }

  const itensmenu = [
    {
      id:1,
      nome:"home",
      link:"/",
      icon:""
    },
    {
      id:2,
      nome:"stnweb",
      link:"/empresa",
      icon:""
    },
    {
      id:3,
      nome:"pagamento",
      link:"/payments",
      icon:""
    },
    // {
    //   id:4,
    //   nome:"Categorias",
    //   link:"",
    //   icon:""
    // },
    {
      id:5,
      nome:"orçamentos",
      link:"/orcamentos",
      icon:""
    },
    {
      id:6,
      nome:"contato",
      link:"/contato",
      icon:""
    },
  ]

  function handleGoTo(routerName){
    navigate(routerName)
  }
  const result = itensmenu.filter(item => item.id === 7);

  return (  
      <div className="menu-stnweb font-aquamax"> 

      <p>{error}</p>

      {currentUser ?
        <>
          {itensmenu.map((item, index) => (
            <div className="item-stnweb " key={item.id}>
              <p onClick={() => handleGoTo(item.link)}>{item.icon && 
              <img src={item.icon} alt={item.nome}/>}{item.nome}</p>
            </div>                                  
          ))}
        </>
        :
        <>
          {result.map((item, index) => (
            <div className="item-stnweb" key={item.id}>
              <p onClick={() => handleGoTo(item.link)}>{item.icon && 
              <img src={item.icon} alt={item.nome}/>}{item.nome}</p>
            </div>                                  
          ))}
        </>
      }

      {currentUser ?
        <div className="item-stnweb azulzinho">
          <p className="btn btn-stnweb  btn-info" style={{backgroundColor:'#00FA9A'}} 
          onClick={() => handleLogoutSubmit()}><FaPowerOff style={{color:'#222'}}/></p>
        </div>
        :
        <div className="item-stnweb azulzinho">
          <p className="btn btn-stnweb  btn-info" onClick={() => handleGoTo('/login')}><FaPowerOff/></p>
        </div>
      }

    </div>   
  );
}
