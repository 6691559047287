const env = 'https://api.gerencianet.com.br/v1/cdn';

export const getPaymentToken = (pay_token, creditCard) => {
    
    return new Promise((resolve, reject) => {
        var s = document.createElement('script');
        s.type = 'text/javascript';

        var v = Math.random() * 1000000;
        s.src = `${env}/${pay_token}/${v}`;
        s.async = false;
        s.id = pay_token;

        if (!document.getElementById(pay_token)) {
            document.getElementsByTagName('head')[0].appendChild(s);
        }

        window['$gn'] = {
            validForm: true,
            processed: false,
            done: {},
            ready: function (fn) {
                window['$gn'].done = fn;
            }
        };

        window['$gn'].ready((checkout) => {
            checkout.getPaymentToken(creditCard, (error, response) => {
                if (error) {
                    reject(error);
                } else {
                    resolve(response.data.payment_token);
                }
            });
        });
    });
    
}