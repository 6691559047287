import valid from "card-validator";

export default function validateInfo(values) {
  let errors = {};

  let creditCard = valid.number(values.cardNumber);

  creditCard.expirationDate = valid.expirationDate(values.cardExpiration);
  creditCard.cvv = valid.cvv(values.cardSecurityCode);
  creditCard.cardholderName = valid.cardholderName(values.cardName);
  creditCard.postalCode = valid.postalCode(values.cardPostalCode);

  errors.show = true;
  errors.variant = "danger";
  errors.message = "Algum erro aconteceu. Tente novamente."
  errors.cname = false;
  errors.cnumber = false;
  errors.ctype = false;
  errors.cexp = false;
  errors.ccvv = false;
  errors.cpostal = false;

  if (values.cardPostalCode === null || !values.cardPostalCode.trim()) {
    errors.message = "Favor preencher o CEP do cartão";
  } else if (creditCard.postalCode.isValid) {
    errors.cpostal = true;
  } else {
    errors.message = "CEP do Cartão Inválido";
  }

  //Card CVV expiration
  if (values.cardSecurityCode === null || !values.cardSecurityCode.trim()) {
    errors.message = "Favor digitar o Código de segurança";
  } else if (creditCard.cvv.isValid) {
    errors.ccvv = true;
  } else {
    errors.message = "Código de Segurança Inválido!";
  }

  //Card Expiration Verification
  if (values.cardExpiration === null || !values.cardExpiration.trim()) {
    errors.message = "Favor preencher o vencimento do cartão";
  } else if (creditCard.expirationDate.isValid) {
    errors.cexp = true;
  } else {
    errors.message = "Data de Vencimento Inválida";
  }


  //Card Number Verification
  if (values.cardNumber === null || !values.cardNumber.trim()) {
    errors.message = "Favor preencher o número do Cartão";
  } else if (creditCard.isValid) {
    errors.cnumber = true;
  } else {
    errors.message = "Número do Cartão inválido";
  }

  //Cardholder Name Verification
  if (values.cardName === null || !values.cardName.trim()) {
    errors.message = "Preencha o Nome Impresso no Cartão";
  } else if (creditCard.cardholderName.isValid) {
    errors.cname = true;
  } else {
    errors.message = "Nome inválido";
  }

  if (
    errors.cname &&
    errors.cnumber &&
    errors.cexp &&
    errors.cpostal &&
    errors.ccvv
  ) {
    errors.variant = "success";
    errors.message = "Cartão de Crédito Válido!";
  }

  return errors;
}
