import React, { useEffect, useState } from 'react'
import { Container, Row,  Col, } from 'react-bootstrap'

import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../contexts/AuthContext"

import { Header } from '../../components/Header'

import api from '../../services/api'
import ReactGA from 'react-ga4';

import { FaRegCheckCircle, FaRegClock, FaTrash } from "react-icons/fa";

export function Orcamentos(){
  const navigate = useNavigate();

  const { currentUser } = useAuth();

  const [loading, setLoading] = useState(true)
  const [backend, setBackend] = useState()

  const [playvideo, setPlayVideo] = useState(false)

  useEffect(() => {
    let isSubscribed = true
    async function getApi() {
      const response = await api.get('/contatos');
      if (isSubscribed) {
        setBackend(response?.data);
        setLoading(false);
      }
    };
    getApi();
    return () => isSubscribed = false
  }, [loading]);

  async function handleUpdateStatusTrue(id) {
    setLoading(true);
    await api.put(`/contatos/${id}`,{
        status: "Respondido"
      });

      setTimeout(() => {
        setLoading(false);
      }, 500)
    
  }

  
  async function handleUpdateStatusFalse(id) {
    setLoading(true);
    await api.put(`/contatos/${id}`,{
        status: "Pendente"
      });
      setTimeout(() => {
        setLoading(false);
      }, 500)
  }
  async function handleRemoveContact(id) {
    setLoading(true);
    await api.delete(`/contatos/${id}`);
      setTimeout(() => {
        setLoading(false);
      }, 500)
  }

  useEffect(() => {
    setTimeout(() => {
      setPlayVideo(true)
    }, 0)
  }, []);

  useEffect(() => {
      if(!currentUser){
        navigate('/')
      }
    return () => {}
    // eslint-disable-next-line
  }, [currentUser]);

  useEffect(() => {
    ReactGA.event({
      action: 'page_orcamentos',
      category: 'pages',
      label: 'entrou na pagina de orcamento',
      value: 100,
    })
  }, []);

  const urlvideobg = 'https://res.cloudinary.com/dcqngalxa/video/upload/v1666128221/video_111_vpn3b1.mp4'

  return(
    <div className="overlay"> 
      {playvideo && <video className="videohome" src={urlvideobg} autoPlay loop muted/>}    
      <Container fluid className={ playvideo? "bgnot" : "bg" }>
        <Header/>
          <Container>
              <Row>
                <Col sm={12} style={{paddingTop:100}}>          
                  {loading ? <p>Aguarde...</p> : currentUser && 
                  backend?.map((conatos, i) => (
                    <div key={conatos.id} className="font-rbt">
                    <Row className="list-orders">
                      <Col md={1}>  
                        {conatos.id}
                      </Col> 
                      <Col md={2}>
                        {conatos.name}
                      </Col>   
                      <Col md={2}>
                        {conatos.whatsapp}
                      </Col>   
                      <Col md={2}>
                        {conatos.status === "Respondido" ?
                        <div style={{color:'#008000', cursor: 'pointer'}} 
                        onClick={() => handleUpdateStatusFalse(conatos.id)}>
                          <FaRegCheckCircle/> {conatos.status}</div> 
                        : 
                        <div style={{color:'#f90', cursor: 'pointer'}} 
                        onClick={() => handleUpdateStatusTrue(conatos.id)}>
                          <FaRegClock/> {conatos.status}
                        </div>
                        }
                      </Col>
                      <Col md={2}>
                        <FaTrash style={{color:'#F00', cursor: 'pointer'}} 
                        onClick={() => handleRemoveContact(conatos.id)}/>
                      </Col> 
                    </Row>
                  </div>
                  ))}
                </Col>
              </Row>
          </Container>
      </Container>
    </div>
  )
}