import { AppRoutes } from './routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AuthProvider } from './contexts/AuthContext';
import { CartProvider } from "./contexts/CartContext";

function App() {
  return (
      <AuthProvider>
        <CartProvider>
          <AppRoutes />
        </CartProvider>      
      </AuthProvider>
  )
}

export default App;
