import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Container, Row, Spinner, 
  // Collapse, Dropdown
} from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { Header } from '../../components/Header'
import { useCopyToClipboard } from '../../hooks'
import payment from '../../services/payment'

import { getPaymentToken } from '../../utils/getPaymentToken'
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css"; 

import { Form } from '@unform/web'
import Input from '../../components/Form/input'
import Radio from '../../components/Form/radio'
import InputMask from '../../components/Form/inputMask'

import CreditCardForm from "../../components/CreditCardForm/CreditCardForm";

import { FaQrcode, FaCheckCircle, FaUser, FaEnvelope, FaRegIdCard, FaWhatsappSquare, FaMapMarked, FaTruck, FaMoneyBillWave } from "react-icons/fa";

export function Payments(){

  const [playvideo, setPlayVideo] = useState(false)
  const urlvideobg = 'https://res.cloudinary.com/dcqngalxa/video/upload/v1666128221/video_111_vpn3b1.mp4'

  useEffect(() => {
    setTimeout(() => {
      setPlayVideo(true)
    }, 0)
  }, []);


  const formRef = useRef(null);
  const formPaymentRef = useRef(null);

  const navigate = useNavigate();
  // eslint-disable-next-line
  const [value, copy, setCopiedText] = useCopyToClipboard()

  const [loading, setLoading] = useState(false)
    // eslint-disable-next-line
  const [visible, setVisible] = useState(false)
  const [paymentselect, setPaymentSelect] = useState('')


  const [qrcode, setQrCode] = useState('')

  // eslint-disable-next-line
  const [showcontent, setShowContent] = useState(false);


// eslint-disable-next-line
  function handleShowContentBox(id){
    setShowContent(prevState => (
      {...prevState, [id]: !prevState[id]}
    ))
    //https://stackoverflow.com/questions/62454545/how-to-collapse-only-one-div-when-using-react-and-map
  }

  // eslint-disable-next-line 
  function handleGoTo(routerName){
    navigate(`/${routerName}`)
  }

  const client = {
    name:"Luis Henrique Russo",
    email:"russo@stnweb.com.br",
    whatsapp:'11953126618',
    cpf:"35146319804",

    address:{
      rua:"Av. Otacílio Tomanik",
      numero:1136,
      complemento:"Apto 33, Bloco Sabiá",
      bairro:"Vila Polopoli",
      cidade:"São Paulo",
      estado:"SP",
      cep:"05363-101"
    }
  }


  // eslint-disable-next-line
  const pacotedeservicotestepix = [
    {
      id:1823736,
      name:"Teste Pix",
      price:0.01,
      categoria:"Testes",
      qtd:1
    }
  ]

  const pacotedeservicotestecartaodecredito = [
    {
      id:123456789,
      name:"Teste CREDIT CARD",
      price:5.01,
      categoria:"Testes",
      qtd:1
    }
  ]

  // eslint-disable-next-line
  const pacotedeservicobasic = [
    {
      id:1,
      name:"Registro BR",
      price:149.90,
      categoria:"Servidor",
      qtd:2      
    },
    {
      id:2,
      name:"Hospedagem Mensal",
      price:199.90,
      categoria:"Servidor",
      qtd:1
    },
    {
      id:3,
      name:"Landing Page",
      price:4200.50,
      categoria:"Web",
      qtd:1
    },
    {
      id:4,
      name:"Marketing Digital",
      price:1499.90,
      categoria:"Web",
      qtd:1
    },
    {
      id:5,
      name:"CRM Marketing",
      price:1499.90,
      categoria:"Web",
      qtd:1
    },
    {
      id:6,
      name:"Site Building 2.0",
      price:900.99,
      categoria:"Web",
      qtd:1
    }
  ]

  const formasdepagamento = [
    {
      id:1,
      label:"Transferência Pix",
      value:"transferencia-pix",
      categoria:"Formas de Pagamento",   
    },
    {
      id:2,
      label:"Transferência Bancária",
      value:"transferencia-bancaria",
      categoria:"Formas de Pagamento",   
    },
    {
      id:3,
      label:"Boleto Bancário",
      value:"boleto",
      categoria:"Formas de Pagamento",   
    },
    {
      id:4,
      label:"Cartão de Crédito",
      value:"cartao-de-credito",
      categoria:"Formas de Pagamento",   
    },
  ]

  const metodosdeenvio = [
    {
      id:11,
      label:"Retirar na Loja",
      value:"retirar",
      categoria:"Formas de Entrega",   
    },
    {
      id:12,
      label:"Correios",
      value:"correios",
      categoria:"Formas de Entrega",   
    },
    {
      id:13,
      label:"Transportadora",
      value:"transportadora",
      categoria:"Formas de Entrega",   
    },
    {
      id:14,
      label:"MotoBoy",
      value:"motoboy",
      categoria:"Formas de Entrega",   
    },
  ]

  const total = pacotedeservicotestecartaodecredito.reduce((prevVal, elem) => prevVal + elem.price, 0);
  const totalitens = pacotedeservicotestecartaodecredito.reduce((prevVal, elem) => prevVal + elem.qtd, 0);
  const comdecimal = total.toFixed(2);
  const totalemstring = comdecimal.toString();
  // console.log(totalemstring);


  async function handleCreatePayment(){
    setLoading(true)
    setQrCode({})
    setCopiedText(null)
      const response = await payment.post('/payment', {
        fullname:client.name,
        cpf:client.cpf,
        price:totalemstring,
        expiration:3600,
      })
    setQrCode(response?.data);
    setVisible(true)
    setLoading(false);
  }


  function handleConverter(valor){
    const converter = valor?.toLocaleString('pt-br', {minimumFractionDigits: 2}) || "";
    return converter;
   }

  async function handleSubmit(data, {reset}) {

  }

  async function handleSubmitPayment(data, {reset}) {
    const {pagamento} = data;
    console.log(pagamento)
    setPaymentSelect(pagamento);
    setQrCode('')
    setCopiedText(null)
  }



  return(

    <div className="overlay"> 

    {playvideo && <video className="videohome"  src={urlvideobg} autoPlay loop muted />}  
    
    <Container fluid className={ playvideo ? "bgnot" : "bg"}>
        <Header/>
        <Container className="pix-form">
            <Row>
              <Col sm={12}>
                <h1 className='pix-title font-aquamax'>MEU CARRINHO</h1>
              </Col>
            </Row>
            <Row>
            <Col sm={12}>
                <Row className="pix-box-title">
                  <div className='font-fira'>•</div>
                  <h5 className='font-aquamax'>Carrinho</h5>                     
                </Row>
                <Container className='item-carrinho' style={{borderRadius:10}}>
                    <Row className="total-carrinho">
                      <Col sm={7}>
                        <div style={{padding:13}}><strong>Produto/Serviço</strong></div>
                      </Col>
                      <Col sm={1}>
                        <div style={{padding:13}}><strong>Qtd</strong></div>
                      </Col>
                      <Col sm={4}>
                        <div style={{padding:13}}><strong>Sub-Total</strong></div>
                      </Col>
                    </Row>
                    {pacotedeservicotestecartaodecredito.map((item =>
                    <Row key={item.id} className="item-carrinho">
                      <Col sm={7}>
                        <div style={{padding:13}}>• <strong> {item.name}</strong></div>
                      </Col>
                      <Col sm={1}>
                        <div style={{padding:13}}><strong>{item.qtd}x</strong></div>
                      </Col>
                      <Col sm={4}>
                        <div style={{padding:13}}><strong>R$ {item.price * item.qtd}</strong></div>
                      </Col>
                      <hr/>
                    </Row>))}
                    <Row className='total-carrinho'style={{borderRadius:10}}>
                      <Col sm={5}>
                        <div style={{padding:12}}><strong>Sub-Total</strong></div>
                      </Col>   
                      <Col sm={3}>
                        <div style={{padding:12}}><strong>{totalitens} itens</strong></div>
                      </Col>  
                      <Col sm={4}>
                        <div style={{padding:12}}><strong>R${handleConverter(total)}</strong></div>
                      </Col>                           
                    </Row>
                </Container>              
              </Col>
            </Row>
            
            <Row style={{marginTop:30}}>
              <Col sm={4}>
                <Row>
                  <Col sm={12}>
                    <Row className="pix-box-title">
                      <div className='font-fira'>•</div>
                      <h5 className='font-aquamax'>Meus Dados</h5>                
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Form onSubmit={handleSubmit} ref={formRef} initialData={client}>
                      <div className="pix-box-title-address font-aquamax">
                        Dados Pessoais
                        <FaUser style={{marginTop:3}}/>
                      </div>
                      <Container className='item-carrinho' style={{paddingTop:18, paddingBottom:5}}>
                        <Row>
                          <Col sm={1}>
                            <FaUser style={{marginTop:16}}/>
                          </Col>
                          <Col sm={11}>
                            <Input name="name" placeholder="digite seu nome"className="pix-input-client"/>
                          </Col>                      
                        </Row>
                        <Row>
                          <Col sm={1}>
                            <FaEnvelope style={{marginTop:16}}/>
                          </Col>
                          <Col sm={11}>
                            <Input name="email" type="email" placeholder="digite seu email" className="pix-input-client"/>
                          </Col>                      
                        </Row>
                        <Row>
                          <Col sm={1}>
                            <FaRegIdCard style={{marginTop:16}}/>
                          </Col>
                          <Col sm={11}>
                            <InputMask name="cpf" mask="999.999.999-99" placeholder="digite seu CPF" className="pix-input-client"/>
                          </Col>                      
                        </Row>
                        <Row>
                          <Col sm={1}>
                            <FaWhatsappSquare style={{marginTop:16}}/>
                          </Col>
                          <Col sm={11}>
                            <InputMask name="whatsapp" mask="(99)9.9999-9999" placeholder="seu whatsapp" className="pix-input-client"/>
                          </Col>                      
                        </Row>                       
                      </Container>

                      <div className="pix-box-title-address font-aquamax" style={{marginTop:16}}>
                        Entrega
                        <FaMapMarked style={{marginTop:3}}/>
                      </div>

                      <Container className='item-carrinho' style={{paddingTop:18, paddingBottom:5}}>
                        <Row>
                          <Col sm={9}>
                            <Input name="address.rua" placeholder="endereço" className="pix-input-client"/>
                          </Col>
                          <Col sm={3} style={{paddingLeft:0}}>
                            <Input name="address.numero" placeholder="Nº" className="pix-input-client"/>
                          </Col>
                        </Row>
                        <Input name="address.complemento" placeholder="complemento" className="pix-input-client"/>
                        <Row>
                          <Col sm={8}>
                            <Input name="address.bairro" placeholder="seu bairro" className="pix-input-client"/>
                          </Col>
                          <Col sm={4} style={{paddingLeft:0}}>
                            <InputMask name="address.cep" mask="99999-999" placeholder="CEP" className="pix-input-client"/>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={9}>
                            <Input name="address.cidade" placeholder="cidade"className="pix-input-client"/>
                          </Col>
                          <Col sm={3}style={{paddingLeft:0}}>
                            <Input name="address.estado" placeholder="estado"className="pix-input-client"/>
                          </Col>
                        </Row>
                        
                    </Container>                   
                  </Form> 
                </Row>                               
              </Col>            
              <Col sm={4}>
                <Row className="pix-box-title">
                  <div className='font-fira'>•</div>
                  <h5 className='font-aquamax'>Forma de Envio/Pagamento</h5>                     
                </Row>
                <div className="pix-box-title-address font-aquamax" style={{padding:13}}>
                  Métodos de Envio
                  <FaTruck style={{marginTop:3}}/>
                </div>                            
                <Container className='item-carrinho' style={{paddingTop:18, paddingBottom:5}}>
                  <Form onSubmit={handleSubmit} ref={formRef} initialData={client} className="radio-envio">
                    <Radio name="envio" options={metodosdeenvio} />
                  </Form>
                </Container>                  
                <div className="pix-box-title-address font-aquamax" style={{padding:13, marginTop:16}}>
                  Escolha sua forma de pagar
                  <FaMoneyBillWave style={{marginTop:3}}/>
                </div>
                <Container className='item-carrinho' style={{padding:15}}>
                  <Form onSubmit={handleSubmitPayment} ref={formPaymentRef} className="radio-envio">
                      <Radio name="pagamento" options={formasdepagamento}/>
                      <Button type="submit" className="btn btn-stnweb" style={{color:"#222", fontWeight:"bold", width:"100%", padding:13}} >
                        SELECIONAR FORMA DE PAGAMENTO
                      </Button>
                  </Form>
                </Container>
              </Col>
              <Col sm={4}>
                <Row className="pix-box-title">
                  <div className='font-fira'>•</div>
                  <h5 className='font-aquamax'>Pagamento</h5>
                </Row>
                <Container className="resumo">
                  <Row className="total-carrinho">
                    <Col sm={12}>
                      <h4 className='font-aquamax center'><strong>Resumo do Pedido</strong></h4>                
                    </Col>                      
                  </Row>
                  <Row> 
                    <Col sm={12}>
                      <p className='font-aquamax'>
                      {paymentselect === "transferencia-pix" ? "Pagamento: Pix" : ""}
                      {paymentselect === "boleto" ? "Pagamento: Boleto Bancário" : ""}
                      {paymentselect === "cartao-de-credito" ? "Pagamento: Cartão de Crédito" : ""}
                      {paymentselect === "transferencia-bancaria" ? "Pagamento: Transferência Bancária" : ""}
                      </p>
                      <p><span className='font-aquamax'>Valor R$</span><strong>{total}</strong></p>
                    </Col>
                  </Row>                
                </Container>

                <>
                  {paymentselect === "transferencia-pix" && qrcode === '' &&
                    <Button className="btn btn-stnweb" 
                    style={{color:"#222", fontWeight:"bold", width:"100%", padding:13}} 
                    onClick={() => handleCreatePayment()}>
                      PAGAR COM PIX <FaQrcode style={{marginBottom:3, marginLeft:10}}/>
                    </Button>                
                  }

                  {loading ? 
                    <div className='qrcode-spinner'>
                      <Spinner animation="border" size="lg" />
                    </div>
                  :
                    <>
                      <div className="ajudando-na-view">
                          {paymentselect === "transferencia-pix" && qrcode !== '' &&
                            <>
                              <div className="pix-box-title-address font-aquamax" style={{padding:13}}>
                              <strong>PAGAMENTO PIX</strong>
                                <FaCheckCircle style={{marginTop:3}}/>
                              </div>                        
                              <Container className='item-carrinho' style={{paddingTop:18, paddingBottom:5}}>               
                                  <div className="qrcode-title">
                                    {qrcode !== '' && paymentselect === "transferencia-pix" &&  <p>Pague com o QR Code Abaixo</p>}
                                  </div>
                              
                                  <div className="qrcode-image">                  
                                    {qrcode !== '' && paymentselect === "transferencia-pix" && 
                                      <img src={qrcode.qrcodeimage} alt=""/>
                                    }
                                  </div>
                                
                                  {qrcode !== '' && paymentselect === "transferencia-pix" && 
                                    <>
                                      <p style={{textAlign:"center",}}>ou copie o código e cole no App do seu Banco.</p>
                                      <textarea className="qrcode-textarea" rows="1" cols="50" 
                                      defaultValue={qrcode?.copiaecola} style={{borderRadius:10, padding:5, color:'#222'}} readOnly disabled/>
                                      
                                      <Button className="btn btn-stnweb" style={{color:"#222", fontWeight:"bold", width:"100%", padding:13}} 
                                        onClick={() => copy(qrcode?.copiaecola)}>
                                        COPIAR CÓDIGO
                                      </Button>
                                    </>
                                  }
                              </Container>
                          </>}
                      </div>
                    </>
                  }
                </>


                <>
                  {paymentselect === "cartao-de-credito" &&
                    <>
                      <div className="pix-box-title-address font-aquamax" style={{padding:13, marginTop:16}}>
                      <strong>Pagamento com Cartão</strong>
                        <FaCheckCircle style={{marginTop:3}}/>
                      </div>
                        <Container className='item-carrinho' style={{paddingTop:18, paddingBottom:5}}>

                       
                            {/* <div className='qrcode-spinner center'>
                                <div>
                                <Spinner animation="border" size="lg" /><br/>
                                Gerando Pagamento por Cartão... 
                                </div>                          
                            </div> */}
                          
                          {/* <CreditCards/> */}


                          <CreditCardForm />
                          
                        </Container>
                    </>
                  }
                </>

                <>
                  {paymentselect === "transferencia-bancaria" &&
                    <>
                      <div className="pix-box-title-address font-aquamax" style={{padding:13}}>
                      <strong>TRANSFERENCIA</strong>
                        <FaCheckCircle style={{marginTop:3}}/>
                      </div>

                      <Container className='item-carrinho' style={{paddingTop:18, paddingBottom:5}}>
                        <p>Método indisponível no momento.</p>                          
                      </Container>
                    </>
                  }
                </>

                <>
                  {paymentselect === "boleto" &&
                    <>
                      <div className="pix-box-title-address font-aquamax" style={{padding:13}}>
                      <strong>BOLETO BANCÁRIO</strong>
                        <FaCheckCircle style={{marginTop:3}}/>
                      </div>

                      <Container className='item-carrinho' style={{paddingTop:18, paddingBottom:5}}>
                        <p>Método indisponível no momento.</p>                          
                      </Container>
                    </>
                  }
                </>
              </Col>
            </Row>            
        </Container>
    </Container>
  </div>
  )
}


export const CreditCards = () => {

    const [number, setNumber] = useState('')
    const [cardname, setCardName] = useState('')
    const [year, setYear] = useState('')
    const [month, setMonth] = useState('')
    const [codseg, setCodSeg] = useState('')
    const [focus, setFocus] = useState('')


    const [tokengerado, setTokengerado] = useState('')
    const [errors, setErrors] = useState('')
    const [loading, setLoading] = useState(false)


  async function handleGetPaymentTokenWithCard(event){
    event.preventDefault();

    if(tokengerado === ''){
      setLoading(true)

      try{
        const card = {
          brand: 'visa', 
          number: number, 
          cvv: codseg, 
          expiration_month: month, 
          expiration_year: year
        }
    
        const token = await getPaymentToken("cde3d2f20673c20899e84abff93f3dd6", card);
        setTokengerado(token)
        setLoading(false)
        console.log(token);

      }catch(error){
        setErrors(error)
        setLoading(false)
      }     
    }    
  }


  async function getPaymentWithBackEnd(){
    //api com await levando dados do cartão os dados de endereço do cliente
    alert('chegamso na função de pagamento! Parabéns!')
  }



return ( 
    
    <div id="PaymentForm">
           <Cards    
             cvc={codseg}
             expiry={`${month}${year}`}             
             name={cardname === '' ? "NOME IMPRESSO" : cardname}
             number={number}
             focused={focus}
           />   

              <form onSubmit={handleGetPaymentTokenWithCard} style={{paddingTop:20}}>
                  <input     
                    type="tel"
                    maxLength="16"
                    name="number"     
                    placeholder="NUMERO DO CARTÃO"
                    onChange={(x) => setNumber(x.target.value)}
                    onFocus={(e) => setFocus(e.target.name)}  
                  />
                  {errors &&<p style={{color:"#f00"}}>{errors.error === "invalid_card_number" && "NÚMERO DO CARTÃO INVÁLIDO"}</p>}
                  <input
                    type="text"     
                    name="name"     
                    placeholder="NOME IMPRESSO NO CARTAO"
                    style={{textTransform: "uppercase", marginTop:5}}
                    onChange={(x) => setCardName(x.target.value)}
                    onFocus={(e) => setFocus(e.target.name)}  
                  />
                  <Row>
                    <Col sm={3}>
                        <input
                        style={{marginTop:5}}
                        type="text"
                        maxLength="2"
                        placeholder="MM" 
                        required
                        name="month"  
                        onChange={(x) => setMonth(x.target.value)}
                        onFocus={(e) => setFocus(e.target.name)}  
                        />
                      </Col>

                      <Col sm={3}>
                        <input
                        style={{marginTop:5}}
                        type="text"
                        maxLength="2"
                        placeholder="AA" 
                        required
                        name="year"  
                        onChange={(x) => setYear(x.target.value)}
                        onFocus={(e) => setFocus(e.target.name)}  
                        />
                      </Col>
                      <Col sm={6}>
                        <input 
                        style={{marginTop:5}}
                        type="number"     
                        name="cvc"
                        placeholder="CVV"     
                        onChange={(x) => setCodSeg(x.target.value)}
                        onFocus={(e) => setFocus(e.target.name)}  
                        />
                      </Col>                     
                    </Row>

                    <p>4012001038443335</p>
                    

                    {tokengerado === "" && errors === "" ?

                      <Button type="submit" className="btn btn-stnweb" style={{color:"#222", fontWeight:"bold", width:"100%", padding:13, marginTop:10}}>
                        CONFIRMAR CARTÃO
                      </Button>

                    :



                    <>
                      {loading ?

                        <div className='qrcode-spinner center'>
                            <div>
                            <Spinner animation="border" size="lg" /><br/>
                            Gerando Pagamento por Cartão... 
                            </div>

                            <div>
                              <Button onClick={()=>{setTokengerado(""); setErrors("");}}className="btn btn-payment-ok" style={{color:"#222", backgroundColor:'#080', fontWeight:"bold", width:"100%", padding:13, marginTop:10}}>
                                CANCELAR OPERAÇÃO
                              </Button>
                            </div>
                         

                            
                        </div>
                        
                        :

                        <>
                          {errors === "" ?
                          <>                          
                            <Button onClick={()=>{getPaymentWithBackEnd()}}className="btn btn-payment-ok" style={{color:"#222", backgroundColor:'#080', fontWeight:"bold", width:"100%", padding:13, marginTop:10}}>
                              PAGAR AGORA
                            </Button>
                            <p></p>
                          </>

                          :

                          <>
                            <Button onClick={() => {setNumber(""); setTokengerado(""); setErrors("");}} className="btn btn-payment-crash" style={{color:"#222", fontWeight:"bold", width:"100%", padding:13, marginTop:10}}>
                              ERRO NO CARTÃO!
                            </Button>
                          <p></p>
                        </>
                          }
                        </>
                      }
                    </>
                    }



                </form>  
             </div> 
          );

        }; 
