import { Row, Col }from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Menu } from '../Menu';

import logo  from '../../assets/logo.svg';

export function Header() {
  const navigate = useNavigate();
  
  function handleHome(){
    navigate('/')
  }

  return(
      <Row className="header">
        <Col sm={4}> 
          <img src={logo} alt="logo-stnweb" className="logotipo" onClick={handleHome}/> 
        </Col>
        <Col sm={8} className="menu-header">
          <Menu />
        </Col> 
      </Row>
  )
}