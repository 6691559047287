import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {Container, Row, Col } from 'react-bootstrap/';

import { Form } from '@unform/web';
import Input from '../../components/Form/input';

import { Title } from '../../components/Title';
import { Header } from '../../components/Header';
import { useAuth } from "../../contexts/AuthContext";

//import Image from "../../assets/bg.png";

export function Login() {
  const navigate = useNavigate();
  
  const { login, currentUser } = useAuth();
  
  const [ loading, setLoading ] = useState(false);
  const [ playvideo, setPlayVideo ] = useState(false)

  const [ user, setUser ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ error, setError ] = useState('');

  async function handleLoginSubmit() {

    try{
      setError('')
      setLoading(true)

      await login(user, password)
      setUser("");
      setPassword("")

      setLoading(false)
    } catch {
      setError(`Complete os campos corretamente!`)
      setLoading(false)
    }   
  }

  function handleHome(){
    navigate('/')
  }
  
  useEffect(() => {
    let t = setTimeout(() => {
      if(currentUser){
        handleHome();
      }
    }, 100);
    return () => clearTimeout(t);
    // eslint-disable-next-line
  }, [currentUser]);
  
  useEffect(() => {
    setTimeout(() => {
      setPlayVideo(true)
    }, 0)
  }, []);

  const urlvideobg = 'https://res.cloudinary.com/dcqngalxa/video/upload/v1666128221/video_111_vpn3b1.mp4'

  return(
    <div className="overlay"> 
    {playvideo && <video className="videohome"  src={urlvideobg} autoPlay loop muted />}  
    
    <Container fluid className={ playvideo? "bgnot" : "bg" }>
      <Header/>
      <Row>
        <Col sm={4}></Col>
        <Col sm={4}>
          <Container className="formlogin font-aquamax">
          <Title title="Faça seu Login" subtitle="entre com seu e-mail e senha"/>
            <Row >
              <Col sm={12} style={{padding:20}}>
                {/* Faça seu Login com E-mail e Senha */}
              </Col>
            </Row>
            <Row>  
            <Col sm={2}></Col>
              <Col sm={8}> 
                  <Form onSubmit={handleLoginSubmit}>
                      <Input 
                      style={{marginTop:10}}
                        type="email"
                        name="email"
                        placeholder="digite seu email"
                        onChange={event => setUser(event.target.value)}
                        value={user}
                      />            
                      <Input
                      style={{marginTop:10}}
                        name="password"
                        type="password"
                        placeholder="digite sua senha"
                        onChange={event => setPassword(event.target.value)}
                        value={password}
                      />
                        <button style={{width:'100%', marginTop:10}}
                        disabled={loading} type="submit" 
                        className="btn btn-stnweb">
                          ENTRAR
                        </button>
                    </Form>
                    <p>{error}</p>           
                </Col>
            </Row>
          </Container>
        </Col>
        <Col sm={4}></Col>
      </Row>
    </Container>
    </div>
    
  )
}